export default {
    'home title': 'Wrapped Filecoin',
    'home tips': 'Stable-balance FIL wrapper for DeFi',
    'home': 'Home',
    'faq': 'Faq',
    'max': 'MAX',
    'you-will-receive': 'You will receive',
    'exchange-rate': 'Exchange rate',
    'transaction-cost': 'Transaction cost',
    'reward-fee': 'Reward fee',
    'reward-fee-tip': 'Liquidity stake protocol, built on top of Filecoin, allows users to earn block rewards without locking up assets or maintaining Storage Provider infrastructure.',
    'unstake-tip-befor': 'Default stFIL unstaking period takes around 3-4 days (80 epochs) to process. After that you can ',
    'unstake-tip-after': 'your rewards in Claim tab',
    'claim-tip-befor': 'You will be able to claim your rewards after the withdraw request has been processed. To unstake your amount go to',
    'claim-tip-after': 'tab',
    'connect-wallet': 'Connect Wallet',
    'statistics': 'statistics',
    'annual-percentage-rate': 'Annual percentage rate',
    'total-staked-with': 'Total staked with STFIL',
    'stakers': 'Stakers',
    'market-cap': 'stFIL market cap',
    'total-claimable-rewards': 'Total claimable rewards',
    'pending-amount': 'Pending amount',
    'faq_1': "What's the minimum deposit?",
    'faq_1_content': "The STFIL agreement allows anyone to earn rewards with deposits as low as 0.1 FIL. Allows holders (assets) to deposit their FIL assets into smart contracts with liquid pledge to mint a 1-for-1 pledge token stFIL, and without being locked in with us, stFIL accumulates pledge rewards wherever it is earned.",
    'faq_2': "What's the maximum deposit?",
    'faq_2_content': "Whatever you want, no limit.",
    'faq_3': "What is Liquid Staking?",
    'faq_3_content': "Liquidity pledge agreements allow users to receive pledge incentives without locking up assets or maintaining the pledge infrastructure. Users of these agreements can deposit pledged tokens and receive tradable liquid tokens in return. The DAO-controlled smart contract then pledges the token with a storage provider (SP) of the DAO's choosing. Funds deposited by the user are controlled by the DAO, and the storage provider never has direct access to the user's assets.",
    'faq_4': "How do I release the pledge?",
    'faq_4_content': "The STFIL protocol always maintains a one-to-one relationship between stFIL and FIL, and the vault will set up deposit reserves that you can trade back into the protocol for FIL as long as there is available liquidity in the protocol to cover the exchange.",
    'faq_5': "How is the pledged APR calculated?",
    'faq_5_content': "APR is denominated in stFIL, not US dollars, and is not a guaranteed or promised return or profit. The STFIL protocol provides the funds pledged by the user to a carefully selected storage provider for token pledge, and the proceeds are recast stFIL and distributed to the user, who can receive the pledge reward within 24 hours after the deposit, without waiting for the validator to activate. The user is presented with the average annualized revenue for the last 7 days.",
}
